import { Component, OnInit } from '@angular/core';

import { AppCommonService } from "./../../../app.common.service";
import { CustomerService } from "./../../services/customer.service";
import { Customer } from "./../../models/customer";

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  constructor(private appCommonService: AppCommonService,
    private customerService: CustomerService) { }

     customerList:Customer[]=[];
    successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {
    this.loadCustomers();
  }


  loadCustomers():void{
    this.appCommonService.showSpinner();
    this.customerService.get().subscribe(
      data=>{
        this.customerList=data;
        console.log(data);
      },
      err=>{
        this.errorMsg=err;
        this.appCommonService.hideSpinner();
      },
      ()=>this.appCommonService.hideSpinner()
    )
  }

}
