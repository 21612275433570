import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppCommonService } from 'src/app/app.common.service';
import { Observable, forkJoin } from 'rxjs';
import { Invoice } from '../models/invoice';
import { InvoiceProduct } from '../models/invoice-product';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private httpClient: HttpClient,
    private appCommonService: AppCommonService) { }

  ServiceUrl: string = this.appCommonService.BaseServiceUrl + "Invoice";

  public save(invoice: Invoice): Observable<string> {
    if (invoice.Id > 0)
      return this.httpClient.put<string>(this.ServiceUrl + "/" + invoice.Id, invoice);

    invoice.Id = 0;
    return this.httpClient.post<string>(this.ServiceUrl, invoice);
  }

  public get(): Observable<Invoice[]> {
    return this.httpClient.get<Invoice[]>(this.ServiceUrl);
  }

  public getById(id: number): Observable<Invoice> {
    return this.httpClient.get<Invoice>(this.ServiceUrl + '/' + id);
  }

  public getInvoiceProductById(id: number): Observable<InvoiceProduct[]> {
    let url = this.ServiceUrl + '/GetInvoiceProduct/' + id
    return this.httpClient.get<InvoiceProduct[]>(url);
  }
}
