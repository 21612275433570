import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';

import { AppCommonService } from "./../../../app.common.service";
import { ProductService } from "./../../services/product.service";
import { TaxService } from "./../../services/tax.service";
import { Product } from "./../../models/product";
import { Tax } from "./../../models/tax";


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private appCommonService: AppCommonService,
    private productService: ProductService,
    private taxService: TaxService) { }

  productAddForm: FormGroup;
  successMsg: string = "";
  errorMsg: string = "";
  taxList: Tax[] = [];

  ngOnInit() {

    this.createProductForm();
    this.loadTaxList();

    this.activeRoute.params.subscribe(params => this.loadProductDetails(params.id));
  }

  loadTaxList() {
    this.appCommonService.showSpinner();
    this.taxService.get().subscribe(
      data => {
        this.taxList = data;

        var tax: Tax = this.taxList.find(x => x.IsDefault);
        if (tax) {
          this.productAddForm.controls.Tax.setValue(tax.TaxPercent);
        }
        console.log(data);
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }

  loadProductDetails(id: number) {
    if (!id)
      return;
      
    this.appCommonService.showSpinner();
    this.productService.getById(id).subscribe(
      data => {
        console.log(data.Brand);
        this.createEditForm(data);
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )

  }

  resetForm(): void {
    this.productAddForm.reset();
    this.productAddForm.controls.Id.setValue(0);
    this.successMsg = "";
    this.errorMsg = "";
  }

  saveProduct() {
    let product: Product = this.productAddForm.value;

    if (this.productAddForm.invalid) {
      this.appCommonService.validateAllFormFields(this.productAddForm);
      console.log(this.productAddForm.errors);
      return;
    }

    this.appCommonService.showSpinner();
    this.productService.save(product).subscribe(
      data => {
        if (data == "Data Saved") {
          this.resetForm();
          this.successMsg = data;
        }
        else {
          this.errorMsg = data;
        }
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }


  createProductForm(): void {
    this.productAddForm = this.formBuilder.group({
      Id: [0],
      Type: ["Product"],
      Brand: ["", Validators.required],
      Product: [],
      ProductType: [],
      PartCode: [],
      Series: [],
      HSN: [""],
      Unit: [],
      UnitPrice: [],
      Tax: [""]
    })
  }

  createEditForm(product: Product): void {

    this.productAddForm = this.formBuilder.group({
      Id: [product.Id],
      Type: [product.Type],
      Brand: [product.Brand],
      Product: [product.Product],
      ProductType: [product.ProductType],
      PartCode: [product.PartCode],
      Series: [product.Series],
      HSN: [product.HSN],
      Unit: [product.Unit],
      UnitPrice: [product.UnitPrice],
      Tax: [product.Tax]
    })
  }

}
