import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'invoice-ag8';

  constructor(private router: Router, private loginService: LoginService) {

  }

  logout() {
    this.loginService.removeAdminTokenAtLocStorage();
    this.router.navigate(["/login"]);
  }
}
