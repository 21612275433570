import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";

import { Customer } from "./../models/customer";
import { AppCommonService } from 'src/app/app.common.service';
import { Observable } from 'rxjs';


@Injectable()
export class CustomerService {

  constructor(private httpClient: HttpClient,
    private appCommonServices: AppCommonService) { }

  ServiceUrl: string = this.appCommonServices.BaseServiceUrl + "CustomerMaster";


  public get(): Observable<Customer[]> {
    return this.httpClient.get<Customer[]>(this.ServiceUrl);
  }

  public getById(id: number): Observable<Customer> {
    let getByIdUrl: string = this.ServiceUrl + "/" + id;
    return this.httpClient.get<Customer>(getByIdUrl);
  }

  public save(customer: Customer): Observable<string> {
    console.log(this.ServiceUrl + "/" + customer.Id);
    if (customer.Id > 0)
      return this.httpClient.put<string>(this.ServiceUrl + "/" + customer.Id, customer);

    return this.httpClient.post<string>(this.ServiceUrl, customer);
  }


}
