import { Component, OnInit } from '@angular/core';

import { AppCommonService } from "./../../../app.common.service";
import { ProductService } from "./../../services/product.service";
import { Product } from "./../../models/product";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  constructor(private appCommonService: AppCommonService,
    private productService: ProductService) { }

    productList:Product[]=[];
    successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {
    this.loadProducts();
  }

  loadProducts():void{
    this.appCommonService.showSpinner();
    this.productService.get().subscribe(
      data=>{
        this.productList=data;
        console.log(data);
      },
      err=>{
        this.errorMsg=err;
        this.appCommonService.hideSpinner();
      },
      ()=>this.appCommonService.hideSpinner()
    )
  }

}
