import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppCommonService } from 'src/app/app.common.service';
import { IEntity } from '../model/ientity';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService<T extends IEntity> {

  constructor(private httpClient: HttpClient,
    private appCommonService: AppCommonService,
    private controllerName: string) { }

  ServiceUrl: string = this.appCommonService.BaseServiceUrl + this.controllerName;

  public save(entity: T): Observable<string> {
    if (entity.Id > 0)
      return this.httpClient.put<string>(this.ServiceUrl + "/" + entity.Id, entity);

    entity.Id = 0;
    return this.httpClient.post<string>(this.ServiceUrl, entity);
  }

  public get(): Observable<T[]> {
    return this.httpClient.get<T[]>(this.ServiceUrl);
  }

  public getById(id: number): Observable<T> {
    return this.httpClient.get<T>(this.ServiceUrl + '/' + id);
  }

}
