import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppCommonService } from 'src/app/app.common.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private appCommanService: AppCommonService,
    private loginService: LoginService,
    private router: Router) { }

  logingForm: FormGroup;
  errorMsg: string = '';

  ngOnInit() {
    this.createLoginForm();
  }

  
  login() {

    if (this.logingForm.controls.UserName.invalid) {
      this.logingForm.controls.UserName.markAsTouched();
      console.log(this.logingForm.errors);
      return false;
    }

    if (this.logingForm.controls.Password.invalid) {
      this.logingForm.controls.Password.markAsTouched();
      console.log(this.logingForm.errors);
      return false;
    }

    let user = this.logingForm.value;
    this.appCommanService.showSpinner();
    this.loginService.getUserToken(user).subscribe(
      data => {        
        this.appCommanService.hideSpinner();
        this.loginService.setAdminTokenAtLocStorage(data);
        this.router.navigateByUrl('/amc');
      },
      err => {
        console.log(err);
        this.errorMsg = err.error;
        this.appCommanService.hideSpinner();
      }
    )
  }

  createLoginForm() {
    this.logingForm = this.formBuilder.group({
      UserName: [null, Validators.required],
      Password: [null, Validators.required]
    })
  }

}
