import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
 import { Ng2AutoCompleteModule } from "ng2-auto-complete";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TaxListComponent } from './masters/componets/tax-list/tax-list.component';
import { AppCommonService } from './app.common.service';
import { TaxService } from './masters/services/tax.service';
import { ProductListComponent } from './masters/componets/product-list/product-list.component';
import { AddProductComponent } from './masters/componets/add-product/add-product.component';
import { ProductService } from './masters/services/product.service';
import { AddInvoiceComponent } from './invoice/components/add-invoice/add-invoice.component';
import { DatePipe } from '@angular/common';
import { InvoiceListComponent } from './invoice/components/invoice-list/invoice-list.component';
import { CustomerListComponent } from './masters/componets/customer-list/customer-list.component';
import { AddCustomerComponent } from './masters/componets/add-customer/add-customer.component';
import { CustomerService } from './masters/services/customer.service';
import { ProductAmcComponent } from './product-amc/components/product-amc/product-amc.component';
import { AddAmcProductListComponent } from './product-amc/add-amc-product-list/add-amc-product-list.component';
import { AmcListComponent } from './product-amc/components/amc-list/amc-list.component';
import { ItemMasterListComponent } from './masters/componets/item-master-list/item-master-list.component';
import { LoginPageComponent } from './login/components/login-page/login-page.component';

@NgModule({
  declarations: [
    AppComponent, TaxListComponent,
    ProductListComponent,AddProductComponent,
    CustomerListComponent,AddCustomerComponent,
    AddInvoiceComponent,
    InvoiceListComponent,
    ProductAmcComponent,
    AddAmcProductListComponent,
    AmcListComponent,
    ItemMasterListComponent,
    LoginPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng4LoadingSpinnerModule.forRoot(),
    Ng2AutoCompleteModule
  ],
  providers: [DatePipe,AppCommonService,TaxService,ProductService,CustomerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
