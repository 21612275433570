import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';

import { AppCommonService } from "./../../../app.common.service";
import { ProductService } from "./../../../masters/services/product.service";
import { Product } from "./../../../masters/models/product";
import { InvoiceProduct } from '../../models/invoice-product';
import { InvoiceService } from '../../services/invoice.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Invoice } from '../../models/invoice';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.css']
})
export class AddInvoiceComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private appCommonService: AppCommonService,
    private productService: ProductService,
    private invoiceService: InvoiceService) { }


  invoiceAddForm: FormGroup;
  stateList: string[] = [];
  successMsg: string = "";
  errorMsg: string = "";
  addProductErrorMsg: string = "";
  productList: Product[] = [];
  productNameList: string[] = [];
  invoiceTaxList: any[] = [];
  deletedInvoiceProduct: InvoiceProduct[] = [];
  taxType = "CGST";

  ngOnInit() {
    this.createAddForm();

    this.stateList = this.appCommonService.StateLists;


    this.invoiceAddForm.controls.InvoiceProducts.valueChanges.subscribe(values => {
      let totalAmount = values.reduce((acc, curr) => acc + curr.Amount, 0)
      this.invoiceAddForm.controls.TotalAmount.setValue(totalAmount);
    });

    this.loadProductList();


    this.loadInvoiceDetail(this.activeRoute.snapshot.params.id);
  }

  loadInvoiceDetail(id: number) {
    if (!id)
      return;

    let getInvoice = this.invoiceService.getById(id);
    let getInvoiceProduct = this.invoiceService.getInvoiceProductById(id);

    this.appCommonService.showSpinner();
    forkJoin([getInvoice, getInvoiceProduct]).subscribe(
      data => {
        this.setInvoiceFormValues(data[0], data[1])
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    );
  }

  loadProductList(): void {

    this.appCommonService.showSpinner();
    this.productService.get().subscribe(
      data => {
        this.productList = data;
        this.productNameList = this.productList.map(x => x.Brand + " " + x.Product + " " + x.Series + " " + x.PartCode);
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }

  changeSate(value: string) {
    this.taxType = (value == this.appCommonService.CompanyDetail.State)
      ? "CGST" : "IGST";

    this.invoiceAddForm.controls.AddNewProduct.reset();
    (<FormGroup>this.invoiceAddForm.controls.AddNewProduct).controls.Id.setValue(0);
    (<FormGroup>this.invoiceAddForm.controls.AddNewProduct).controls.InvoiceId.setValue(0);
    (<FormGroup>this.invoiceAddForm.controls.AddNewProduct).controls.IsDeleted.setValue(0);
  }

  onProductSelect(value: string) {

    let invoiceProductsFormControl = <FormArray>(this.invoiceAddForm.controls.InvoiceProducts);

    let productAlreadyAdded = (invoiceProductsFormControl.controls
      .filter(x => (<FormGroup>x).controls.Name.value == value).length
      > 0);
    if (productAlreadyAdded) {
      this.addProductErrorMsg = "Product already added";
      return;
    }
    else {
      this.addProductErrorMsg = "";
    }


    let selectedItems = this.productList.filter(function (item) {
      return (item.Brand + " " + item.Product + " " + item.Series + " " + item.PartCode) == value;
    });

    if (selectedItems.length <= 0)
      return;

    let addNewProductGroup = (<FormGroup>this.invoiceAddForm.controls.AddNewProduct);


    addNewProductGroup.controls.HSN.setValue(selectedItems[0].HSN);
    addNewProductGroup.controls.Unit.setValue(selectedItems[0].Unit);
    addNewProductGroup.controls.UnitPrice.setValue(selectedItems[0].UnitPrice);
    addNewProductGroup.controls.TaxPer.setValue(selectedItems[0].Tax);

    if (this.taxType == "CGST") {
      let splitGstPer = selectedItems[0].Tax / 2;
      addNewProductGroup.controls.CGSTPer.setValue(splitGstPer);
      addNewProductGroup.controls.SGSTPer.setValue(splitGstPer);
    } else {
      addNewProductGroup.controls.IGSTPer.setValue(selectedItems[0].Tax);
    }

  }

  calculateAmount(): void {

    let addNewProductGroup = (<FormGroup>this.invoiceAddForm.controls.AddNewProduct);

    let qty = addNewProductGroup.controls.Qty.value;
    let unitPrice = addNewProductGroup.controls.UnitPrice.value;
    let cgstPer = addNewProductGroup.controls.CGSTPer.value;
    let sgstPer = addNewProductGroup.controls.SGSTPer.value;
    let igstPer = addNewProductGroup.controls.IGSTPer.value;
    let cgstAmount = 0;
    let sgstAmount = 0;
    let igstAmount = 0;

    // if (!qty || !unitPrice)
    //   return;

    let netAmount = parseFloat((qty * unitPrice).toFixed(2));
    cgstAmount = parseFloat(((cgstPer * netAmount) / 100).toFixed(2));
    sgstAmount = parseFloat(((sgstPer * netAmount) / 100).toFixed(2));
    igstAmount = parseFloat(((igstPer * netAmount) / 100).toFixed(2));
    let totalAmount = parseFloat((netAmount + cgstAmount + sgstAmount + igstAmount).toFixed(2));

    addNewProductGroup.controls.NetAmount.setValue(netAmount);
    addNewProductGroup.controls.CGSTAmount.setValue(cgstAmount);
    addNewProductGroup.controls.SGSTAmount.setValue(sgstAmount);
    addNewProductGroup.controls.IGSTAmount.setValue(igstAmount);

    addNewProductGroup.controls.Amount.setValue(totalAmount);
  }

  addInvoiceProduct(): void {

    if (this.addProductErrorMsg != "")
      return

    let addNewProductGroup = (<FormGroup>this.invoiceAddForm.controls.AddNewProduct);
    if (addNewProductGroup.invalid) {
      this.appCommonService.validateAllFormFields(addNewProductGroup);
     
        console.log(addNewProductGroup.errors);
      return;
    }


    let invoiceProductsFormControl = <FormArray>(this.invoiceAddForm.controls.InvoiceProducts);
    let addNewProductFormControl = this.copyFormControl(this.invoiceAddForm.controls.AddNewProduct);

    if (this.invoiceAddForm.controls.InvoiceNo.value) {
      (<FormGroup>addNewProductFormControl).controls.InvoiceId.setValue(this.invoiceAddForm.controls.InvoiceNo.value)
    }
    invoiceProductsFormControl.push(addNewProductFormControl);

    this.invoiceAddForm.controls.AddNewProduct.reset();
    (<FormGroup>this.invoiceAddForm.controls.AddNewProduct).controls.Id.setValue(0);
    (<FormGroup>this.invoiceAddForm.controls.AddNewProduct).controls.InvoiceId.setValue(0);
    (<FormGroup>this.invoiceAddForm.controls.AddNewProduct).controls.IsDeleted.setValue(0);

    this.enableDisableState();

    this.loadTaxList(this.invoiceAddForm.value.InvoiceProducts);

  }

  loadTaxList(invoiceProduct: InvoiceProduct[]): void {

    let hsnList = invoiceProduct.map(x => x.HSN)
      .filter((item, i, ar) => ar.indexOf(item) === i);

    let invoiceTaxList: any[] = [];
    hsnList.forEach(item => {
      let hsnGrpInvoiceProduct = invoiceProduct.filter(x => x.HSN === item);

      invoiceTaxList.push({
        HSN: item,
        TaxableAmount: hsnGrpInvoiceProduct.map(x => x.NetAmount).reduce((a, b) => a + b, 0),
        CGSTPer: hsnGrpInvoiceProduct[0].CGSTPer,
        CGSTAmount: hsnGrpInvoiceProduct.map(x => x.CGSTAmount).reduce((a, b) => a + b, 0),
        SGSTPer: hsnGrpInvoiceProduct[0].SGSTPer,
        SGSTAmount: hsnGrpInvoiceProduct.map(x => x.SGSTAmount).reduce((a, b) => a + b, 0),
        IGSTPer: hsnGrpInvoiceProduct[0].IGSTPer,
        IGSTAmount: hsnGrpInvoiceProduct.map(x => x.IGSTAmount).reduce((a, b) => a + b, 0),
      });
    });

    this.invoiceTaxList = invoiceTaxList;
  }

  deleteInvoiceProduct(index: number): void {
    let invoiceProductsFormControl = <FormArray>(this.invoiceAddForm.controls.InvoiceProducts);
    let selectedInvoiceProduct = (<FormGroup>invoiceProductsFormControl.controls[index]);

    if (selectedInvoiceProduct.controls.Id.value) {
      let selectedInvoiceProduct = (<FormGroup>invoiceProductsFormControl.controls[index]);
      selectedInvoiceProduct.controls.IsDeleted.setValue(true);
      this.deletedInvoiceProduct.push(selectedInvoiceProduct.value);
    }
    invoiceProductsFormControl.removeAt(index);
    this.enableDisableState();
  }

  enableDisableState() {
    // if ((<FormArray>this.invoiceAddForm.controls.InvoiceProducts).length) {
    //   this.invoiceAddForm.controls.State.disable();
    // } else {
    //   this.invoiceAddForm.controls.State.enable();
    // }
  }

  saveInvoice() {

    if (this.invoiceAddForm.controls.CustomerName.invalid) {
      this.invoiceAddForm.controls.CustomerName.markAsTouched();
      //this.appCommonService.validateAllFormFields(this.invoiceAddForm);
      console.log(this.invoiceAddForm.errors);
      return;
    }

    let invoice = this.invoiceAddForm.value;
    invoice.Id = invoice.InvoiceNo;
    invoice.InvoiceProducts.push(...this.deletedInvoiceProduct);

    this.appCommonService.showSpinner();
    this.invoiceService.save(invoice).subscribe(
      data => {
        this.successMsg = data;
        this.invoiceAddForm.reset();
        this.invoiceAddForm.controls.State.setValue('Maharashtra');
        let invoiceProductsFormControl = <FormArray>(this.invoiceAddForm.controls.InvoiceProducts);
        invoiceProductsFormControl.clear();
        this.invoiceTaxList = [];
      },
      err => {
        this.errorMsg = err;
      },
      () => this.appCommonService.hideSpinner()
    )

  }



  createAddNewProduct(): FormGroup {
    return this.formBuilder.group({
      Id: [0],
      Name: [, Validators.required],
      Description: [],
      HSN: [],
      Qty: [, Validators.required],
      Unit: [],
      UnitPrice: [, Validators.required],
      NetAmount: [, Validators.required],
      TaxPer: [],
      CGSTPer: [],
      SGSTPer: [],
      IGSTPer: [],
      CGSTAmount: [],
      SGSTAmount: [],
      IGSTAmount: [],
      Amount: [, Validators.required],
      IsDeleted: [false],
      InvoiceId: [0]
    });
  }


  editInvoiceProduct(invoiceProduct: InvoiceProduct, invoiceNo: number): FormGroup {
    return this.formBuilder.group({
      Id: [invoiceProduct.Id],
      Name: [invoiceProduct.Name, Validators.required],
      Description: [invoiceProduct.Description],
      HSN: [invoiceProduct.HSN],
      Qty: [invoiceProduct.Qty, Validators.required],
      Unit: [invoiceProduct.Unit],
      UnitPrice: [invoiceProduct.UnitPrice, Validators.required],
      NetAmount: [invoiceProduct.NetAmount, Validators.required],
      TaxPer: [invoiceProduct.TaxPer],
      CGSTPer: [invoiceProduct.CGSTPer],
      SGSTPer: [invoiceProduct.SGSTAmount],
      IGSTPer: [invoiceProduct.IGSTPer],
      CGSTAmount: [invoiceProduct.CGSTAmount],
      SGSTAmount: [invoiceProduct.SGSTAmount],
      IGSTAmount: [invoiceProduct.IGSTAmount],
      Amount: [invoiceProduct.Amount, Validators.required],
      IsDeleted: [invoiceProduct.IsDeleted],
      InvoiceId: [invoiceNo]
    });
  }


  createAddForm(): void {
    this.invoiceAddForm = this.formBuilder.group({
      InvoiceNo: [],
      InvoiceDate: [this.datePipe.transform(new Date(), 'y-MM-dd'), Validators.required],
      CustomerName: [, Validators.required],
      Address: [],
      City: [],
      DeliveryNote: [],
      ModeTermofPayment: [],
      BuyerOrderNo: [],
      BuyerOrderDate: [],
      DispatchDocumentNo: [],
      DispatchNoteDate: [],
      DispatchedThrough: [],
      Destination: [],
      TermsOfDelivery: [],
      AddNewProduct: this.createAddNewProduct(),
      InvoiceProducts: this.formBuilder.array([]),
      TotalAmount: [, Validators.required],
      State: ['Maharashtra', Validators.required],
      PhoneNo: [],
      EmailId: [],
    });
  }

  setInvoiceFormValues(invoice: Invoice, invoiceProducts: InvoiceProduct[]) {

    this.invoiceAddForm.controls.InvoiceNo.setValue(invoice.Id);
    this.invoiceAddForm.controls.InvoiceDate.setValue(this.datePipe.transform(invoice.InvoiceDate, 'y-MM-dd'));
    this.invoiceAddForm.controls.CustomerName.setValue(invoice.CustomerName);
    this.invoiceAddForm.controls.Address.setValue(invoice.Address);
    this.invoiceAddForm.controls.City.setValue(invoice.City);
    this.invoiceAddForm.controls.DeliveryNote.setValue(invoice.DeliveryNote);
    this.invoiceAddForm.controls.ModeTermofPayment.setValue(invoice.ModeTermofPayment);
    this.invoiceAddForm.controls.BuyerOrderNo.setValue(invoice.BuyerOrderNo);
    this.invoiceAddForm.controls.BuyerOrderDate.setValue(invoice.BuyerOrderDate);
    this.invoiceAddForm.controls.DispatchDocumentNo.setValue(invoice.DispatchDocumentNo);
    this.invoiceAddForm.controls.DispatchNoteDate.setValue(invoice.DispatchNoteDate);
    this.invoiceAddForm.controls.DispatchedThrough.setValue(invoice.DispatchedThrough);
    this.invoiceAddForm.controls.Destination.setValue(invoice.Destination);
    this.invoiceAddForm.controls.TermsOfDelivery.setValue(invoice.TermsOfDelivery);
    this.invoiceAddForm.controls.TotalAmount.setValue(invoice.TotalAmount, Validators.required);
    this.invoiceAddForm.controls.State.setValue(invoice.State, Validators.required);
    this.invoiceAddForm.controls.PhoneNo.setValue(invoice.PhoneNo);
    this.invoiceAddForm.controls.EmailId.setValue(invoice.EmailId);


    let invoiceProductsFormControl = <FormArray>(this.invoiceAddForm.controls.InvoiceProducts)
    invoiceProducts.forEach(invoiceProduct => {
      invoiceProductsFormControl.push(this.editInvoiceProduct(invoiceProduct, invoice.Id));
    });

    this.enableDisableState();
    this.loadTaxList(this.invoiceAddForm.value.InvoiceProducts);

  }

  createEditForm(invoice: Invoice): void {

    this.invoiceAddForm = this.formBuilder.group({
      InvoiceNo: [invoice.Id],
      InvoiceDate: [this.datePipe.transform(new Date(invoice.InvoiceDate), 'y-MM-dd'), Validators.required],
      CustomerName: [invoice.CustomerName],
      Address: [invoice.Address],
      City: [invoice.City],
      DeliveryNote: [invoice.DeliveryNote],
      ModeTermofPayment: [invoice.ModeTermofPayment],
      BuyerOrderNo: [invoice.BuyerOrderNo],
      BuyerOrderDate: [invoice.BuyerOrderDate],
      DispatchDocumentNo: [invoice.DispatchDocumentNo],
      DispatchNoteDate: [invoice.DispatchNoteDate],
      DispatchedThrough: [invoice.DispatchedThrough],
      Destination: [invoice.Destination],
      TermsOfDelivery: [invoice.TermsOfDelivery],
      AddNewProduct: this.createAddNewProduct(),
      InvoiceProducts: this.formBuilder.array([]),
      TotalAmount: [invoice.TotalAmount, Validators.required],
      State: [invoice.State, Validators.required],
      PhoneNo: [invoice.PhoneNo],
      EmailId: [invoice.EmailId],
    });
  }


  copyFormControl(control: AbstractControl) {
    if (control instanceof FormControl) {
      return new FormControl(control.value);
    } else if (control instanceof FormGroup) {
      const copy = new FormGroup({});
      Object.keys(control.getRawValue()).forEach(key => {
        copy.addControl(key, this.copyFormControl(control.controls[key]));
      });
      return copy;
    } else if (control instanceof FormArray) {
      const copy = new FormArray([]);
      control.controls.forEach(control => {
        copy.push(this.copyFormControl(control));
      })
      return copy;
    }
  }


}
