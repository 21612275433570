import { Component, OnInit } from '@angular/core';
import { Invoice } from '../../models/invoice';
import { AppCommonService } from 'src/app/app.common.service';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {

  constructor(private appCommonService: AppCommonService,
    private invoiceService: InvoiceService) { }

  invoiceList: Invoice[] = [];
  successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {
    this.loadInvoices();

  }

  loadInvoices(): void {
    this.appCommonService.showSpinner();
    this.invoiceService.get().subscribe(
      data => {
        this.invoiceList = data;
        console.log(data);
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }

}
