import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppCommonService } from 'src/app/app.common.service';
import { ProductAmcService } from '../../services/product-amc.service';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { Amc } from '../../models/amc';
import { AmcProduct } from '../../models/amc-product';
import { LoginService } from 'src/app/login/services/login.service';

@Component({
  selector: 'app-product-amc',
  templateUrl: './product-amc.component.html',
  styleUrls: ['./product-amc.component.css']
})
export class ProductAmcComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private appCommonService: AppCommonService,
    private productAMCService: ProductAmcService,
    private loginService: LoginService) {
  }

  addEditFrom: FormGroup;
  stateList: string[] = [];
  successMsg: string = "";
  errorMsg: string = "";
  addProductErrorMsg: string = '';
  deletedAMCProduct: AmcProduct[] = [];

  ngOnInit() {
    this.loginService.isUserLogedIn();

    this.createAddForm();

    this.stateList = this.appCommonService.StateLists;

    this.loadAMCDetail(this.activeRoute.snapshot.params.id);
  }

  loadAMCDetail(id: number) {

    if (!id) {
      return;
    }

    let getAMC = this.productAMCService.getById(id);
    let getAMCProduct = this.productAMCService.getAMCProductById(id);

    this.appCommonService.showSpinner();
    forkJoin([getAMC, getAMCProduct]).subscribe(
      data => {
        this.setInvoiceFormValues(data[0], data[1])
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    );

  }


  setInvoiceFormValues(amc: Amc, amcProducts: AmcProduct[]) {

    this.addEditFrom.controls.Id.setValue(amc.Id);
    this.addEditFrom.controls.Date.setValue(this.datePipe.transform(amc.Date, 'y-MM-dd'));
    this.addEditFrom.controls.InvoiceNo.setValue(amc.InvoiceNo);
    this.addEditFrom.controls.CustomerName.setValue(amc.CustomerName);
    this.addEditFrom.controls.Address.setValue(amc.Address);
    this.addEditFrom.controls.City.setValue(amc.City);
    this.addEditFrom.controls.State.setValue(amc.State);
    this.addEditFrom.controls.ContactPerson.setValue(amc.ContactPerson);
    this.addEditFrom.controls.MobileNo.setValue(amc.MobileNo);
    this.addEditFrom.controls.Phone.setValue(amc.PhoneNo);
    this.addEditFrom.controls.EmailId.setValue(amc.EmailId);
    this.addEditFrom.controls.GSTNo.setValue(amc.GSTNo);

    let amcProductsFormControl = <FormArray>(this.addEditFrom.controls.AMCProducts)
    amcProducts.forEach(amcProduct => {
      amcProductsFormControl.push(this.editAmcProduct(amcProduct, amc.Id));
    });

  }

  editAmcProduct(amcProduct: AmcProduct, amcId: number): FormGroup {
    return this.formBuilder.group({
      Id: [amcProduct.Id],
      Make: [amcProduct.Make, Validators.required],
      Model: [amcProduct.Model],
      MCSrNo: [amcProduct.MCSrNo],
      MaxCap: [amcProduct.MaxCap, Validators.required],
      PlatformSize: [amcProduct.PlatformSize],
      VCNo: [amcProduct.VCNo, Validators.required],
      NextVCDate: [amcProduct.NextVCDate, Validators.required],
      IsDeleted: [amcProduct.IsDeleted],
      AMCId: [amcId]
    });
  }


  addAMCProduct(): void {


    let addNewProductGroup = (<FormGroup>this.addEditFrom.controls.AddNewProduct);
    if (addNewProductGroup.invalid) {
      this.appCommonService.validateAllFormFields(addNewProductGroup);
      console.log(addNewProductGroup.errors);
      return;
    }

    let amcProductsFormControl = <FormArray>(this.addEditFrom.controls.AMCProducts);
    let addNewProductFormControl = this.copyFormControl(this.addEditFrom.controls.AddNewProduct);

    if (this.addEditFrom.controls.Id.value) {
      (<FormGroup>addNewProductFormControl).controls.AMCId.setValue(this.addEditFrom.controls.Id.value)
    }

    amcProductsFormControl.push(addNewProductFormControl);

    let nextYearDate = new Date();
    nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

    this.addEditFrom.controls.AddNewProduct.reset();
    this.addProductErrorMsg = '';
    (<FormGroup>this.addEditFrom.controls.AddNewProduct).controls.Id.setValue(0);
    (<FormGroup>this.addEditFrom.controls.AddNewProduct).controls.NextVCDate.setValue(this.datePipe.transform(nextYearDate, 'y-MM-dd'));
    (<FormGroup>this.addEditFrom.controls.AddNewProduct).controls.AMCId.setValue(0);
    (<FormGroup>this.addEditFrom.controls.AddNewProduct).controls.IsDeleted.setValue(false);

  }

  deleteAMCProduct(index: number): void {
    let amcProductsFormControl = <FormArray>(this.addEditFrom.controls.AMCProducts);
    let selectedAMCProduct = (<FormGroup>amcProductsFormControl.controls[index]);

    if (selectedAMCProduct.controls.Id.value) {
      let selectedAMCProduct = (<FormGroup>amcProductsFormControl.controls[index]);
      selectedAMCProduct.controls.IsDeleted.setValue(true);
      this.deletedAMCProduct.push(selectedAMCProduct.value);
    }
    amcProductsFormControl.removeAt(index);
  }

  saveInvoice() {

    if (this.addEditFrom.controls.CustomerName.invalid) {
      this.addEditFrom.controls.CustomerName.markAsTouched();
      //this.appCommonService.validateAllFormFields(this.invoiceAddForm);
      console.log(this.addEditFrom.errors);
      return;
    }

    if (this.addEditFrom.value.AMCProducts.length <= 0) {
      this.addProductErrorMsg = "Please Add Product by Clicking Add button";
      return;
    }

    let AMC = this.addEditFrom.value;
    AMC.AMCProducts.push(...this.deletedAMCProduct);

    this.appCommonService.showSpinner();
    this.productAMCService.save(AMC).subscribe(
      data => {
        this.successMsg = data;
        this.addEditFrom.reset();
        this.addEditFrom.controls.Id.setValue(0);
        this.addEditFrom.controls.State.setValue('Maharashtra');
        this.addEditFrom.controls.Date.setValue(this.datePipe.transform(new Date(), 'y-MM-dd'));
        let invoiceProductsFormControl = <FormArray>(this.addEditFrom.controls.AMCProducts);
        invoiceProductsFormControl.clear();
        let amcNewProduct = (<FormGroup>this.addEditFrom.controls.AddNewProduct);
        amcNewProduct.controls.Id.setValue(0);
        amcNewProduct.controls.AMCId.setValue(0);
        amcNewProduct.controls.IsDeleted.setValue(false);
        amcNewProduct.controls.NextVCDate.setValue(this.datePipe.transform(this.getNextYearDate(), 'y-MM-dd'));
      },
      err => {
        this.errorMsg = err.error.Message;
      },
      () => this.appCommonService.hideSpinner()
    )

  }

  createAddForm(): void {
    this.addEditFrom = this.formBuilder.group({
      Id: [0],
      Date: [this.datePipe.transform(new Date(), 'y-MM-dd'), Validators.required],
      InvoiceNo: [, Validators.required],
      CustomerName: [, Validators.required],
      Address: [],
      City: [],
      State: ["Maharashtra", Validators.required],
      ContactPerson: [],
      MobileNo: [],
      Phone: [],
      EmailId: [],
      GSTNo: [],
      TotalAmount: [],
      AddNewProduct: this.createAddNewProduct(),
      AMCProducts: this.formBuilder.array([]),
    })
  }

  getNextYearDate(): Date {
    let nextYearDate = new Date();
    nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
    return nextYearDate;
  }

  createAddNewProduct(): FormGroup {

    return this.formBuilder.group({
      Id: [0],
      Make: [, Validators.required],
      Model: [],
      MCSrNo: [],
      MaxCap: [, Validators.required],
      PlatformSize: [],
      VCNo: [, Validators.required],
      Amount: [],
      NextVCDate: [this.datePipe.transform(this.getNextYearDate(), 'y-MM-dd'), Validators.required],
      IsDeleted: [false],
      AMCId: [0]
    });
  }

  copyFormControl(control: AbstractControl) {
    if (control instanceof FormControl) {
      return new FormControl(control.value);
    } else if (control instanceof FormGroup) {
      const copy = new FormGroup({});
      Object.keys(control.getRawValue()).forEach(key => {
        copy.addControl(key, this.copyFormControl(control.controls[key]));
      });
      return copy;
    } else if (control instanceof FormArray) {
      const copy = new FormArray([]);
      control.controls.forEach(control => {
        copy.push(this.copyFormControl(control));
      })
      return copy;
    }
  }

}
