import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-master-list',
  templateUrl: './item-master-list.component.html',
  styleUrls: ['./item-master-list.component.css']
})
export class ItemMasterListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
