import { Component, OnInit } from '@angular/core';
import { Amc } from '../../models/amc';
import { ProductAmcService } from '../../services/product-amc.service';
import { AppCommonService } from 'src/app/app.common.service';
import { AmcProductReport } from '../../models/amc-product-report';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoginService } from 'src/app/login/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-amc-list',
  templateUrl: './amc-list.component.html',
  styleUrls: ['./amc-list.component.css']
})
export class AmcListComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private appCommonService: AppCommonService,
    private amcService: ProductAmcService,
    private loginService: LoginService) { }

  searchForm: FormGroup;
  amcProductList: AmcProductReport[] = [];
  successMsg: string = "";
  errorMsg: string = "";



  ngOnInit() {
    this.loginService.isUserLogedIn();

    this.createSearchForm();
    this.loadAmcProducts();
  }

  loadAmcProducts(): void {
    let searchEntity = this.searchForm.value;

    this.appCommonService.showSpinner();
    this.amcService.getAmcProdcutReport(searchEntity).subscribe(
      data => {
        this.amcProductList = data;
        console.log(data);
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }

  resetSearch() {
    this.searchForm.reset();
  }


  createSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      CustomerName: [],
      Make: [],
      AMCDateFrom: [],
      AMCDateTo: [],
      NextVCDateFrom: [],
      NextVCDateTo: []
    });
  }

}
