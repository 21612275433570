import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";

import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Tax } from './masters/models/tax';
import { Company } from './masters/models/company';

@Injectable()
export class AppCommonService {

    constructor(private spinnerService: Ng4LoadingSpinnerService) { }

    // Property
    public get BaseServiceUrl(): string {
       //  return "http://localhost:49872/api/";
        return "https://paithanapmc.org.in/vir_weighingamc/api/";
       // return "http://localhost:300/api/";
    }

    public showSpinner() {
        this.spinnerService.show();
    }

    public hideSpinner() {
        this.spinnerService.hide();
    }


    public validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }


    public get StateLists(): string[] {
        return this._stateLists;
    }

    public get CompanyDetail(): Company {
        return {
            Name: 'Test',
            City: 'Pune',
            State: 'Maharashtra'
        }
    }


    private _stateLists: string[] = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Orissa",
        "Punjab",
        "Pondicherry",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ]


}
