import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-amc-product-list',
  templateUrl: './add-amc-product-list.component.html',
  styleUrls: ['./add-amc-product-list.component.css']
})
export class AddAmcProductListComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, ) { }

  @Input()
  AddNewProduct: FormGroup;

  ngOnInit() {
  }



}
