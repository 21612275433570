import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppCommonService } from 'src/app/app.common.service';
import { LoginUser } from '../models/login-user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient,
    private appCommonService: AppCommonService,
    private router: Router) { }

  ServiceUrl: string = this.appCommonService.BaseServiceUrl + "Login";

  public getUserToken(user: LoginUser): Observable<string> {
    let url = this.ServiceUrl + "/GetUserToken";
    return this.httpClient.post<string>(url, user);
  }


  private _IsAdminLogin: boolean = false;
  public get IsAdminLogin(): boolean {
    return this._IsAdminLogin;
  }

  public isUserLogedIn() {
    if (!this.IsAdminLogin) {
      this.router.navigateByUrl('/login');
    }
  }

  setAdminTokenAtLocStorage(tokenNo: string): void {
    sessionStorage.setItem("UserToken", JSON.stringify(tokenNo));
    this._IsAdminLogin = true;
  }

  getAdminTokenAtLocStorage(): string {
    let adminToken = (JSON.parse(sessionStorage.getItem("UserToken")) as string);
    this._IsAdminLogin = (adminToken != null);
    console.log(this.IsAdminLogin);
    return adminToken;
  }

  removeAdminTokenAtLocStorage(): void {
    sessionStorage.removeItem("UserToken");
    this._IsAdminLogin = false;
  }

}
