import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

import { Tax } from "./../models/tax";
import { AppCommonService } from 'src/app/app.common.service';


@Injectable()
export class TaxService {

  constructor(private httpClient: HttpClient,
    private appCommonService: AppCommonService) { }

  ServiceUrl: string = this.appCommonService.BaseServiceUrl + "TaxMaster";


  public get(): Observable<Tax[]> {
    return this.httpClient.get<Tax[]>(this.ServiceUrl);
  }

  public save(tax: Tax): Observable<string> {
    console.log(this.ServiceUrl);
    if (tax.Id > 0)
      return this.httpClient.put<string>(this.ServiceUrl + "/" + tax.Id, tax);

    return this.httpClient.post<string>(this.ServiceUrl, tax);
  }

}
