import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';

import { AppCommonService } from "./../../../app.common.service";
import { Customer } from "./../../models/customer";
import { CustomerService } from "./../../services/customer.service";

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private appCommonService: AppCommonService,
    private customerService: CustomerService) { }

  customerAddForm: FormGroup;
  stateList: string[] = [];
  successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {

    this.createAddForm();

    this.stateList = this.appCommonService.StateLists;

    this.activeRoute.params.subscribe(params => this.loadCustomerDetails(params.id));
  }

  loadCustomerDetails(id: number) {
    if (!id)
      return;

    this.appCommonService.showSpinner();
    this.customerService.getById(id).subscribe(
      data => {
        this.createEditForm(data);
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )

  }


  resetForm(): void {
    this.customerAddForm.reset();
    this.customerAddForm.controls.Id.setValue(0);
    this.successMsg = "";
    this.errorMsg = "";
  }

  saveProduct() {
    let customer: Customer = this.customerAddForm.value;

    if (this.customerAddForm.invalid) {
      this.appCommonService.validateAllFormFields(this.customerAddForm);
      console.log(this.customerAddForm.errors);
      return;
    }

    this.appCommonService.showSpinner();
    this.customerService.save(customer).subscribe(
      data => {
        if (data == "Data Saved") {
          this.resetForm();
          this.successMsg = data;
        }
        else {
          this.errorMsg = data;
        }
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }

  createAddForm(): void {
    this.customerAddForm = this.formBuilder.group({
      Id: [0],
      Name: [, Validators.required],
      GSTNo: [],
      Address: [],
      City: [],
      State: ["", Validators.required],
      ContactPerson: [],
      MobileNo: [],
      Phone: [],
      EmailId: [],
      Website: []
    })
  }

  createEditForm(customer: Customer): void {
    this.customerAddForm = this.formBuilder.group({
      Id: [customer.Id],
      Name: [customer.Name, Validators.required],
      GSTNo: [customer.GSTNo],
      Address: [customer.Address],
      City: [customer.City],
      State: [customer.State, Validators.required],
      ContactPerson: [customer.ContactPerson],
      MobileNo: [customer.MobileNo],
      Phone: [customer.Phone],
      EmailId: [customer.EmailId],
      Website: [customer.Website]
    })
  }

}
