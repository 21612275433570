import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { AppCommonService } from 'src/app/app.common.service';
import { Amc } from '../models/amc';
import { Observable } from 'rxjs';
import { AmcProductReport } from '../models/amc-product-report';
import { AmcProduct } from '../models/amc-product';

@Injectable({
  providedIn: 'root'
})
export class ProductAmcService extends BaseService<Amc> {

  constructor(private httpClientChild: HttpClient,
    private appCommonServiceChild: AppCommonService) {
    super(httpClientChild, appCommonServiceChild, "ProductAMC")
  }

  public getAmcProdcutReport(searchEntity: any): Observable<AmcProductReport[]> {
    let url = this.ServiceUrl + "/SearchAMCProductReportList";
    return this.httpClientChild.post<AmcProductReport[]>(url, searchEntity);
  }

  public getAMCProductById(id: number): Observable<AmcProduct[]> {

    

    let url = this.ServiceUrl + "/GetAMCProduct/" + id;
    return this.httpClientChild.get<AmcProduct[]>(url);
  }
}
