import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { ProductListComponent } from './masters/componets/product-list/product-list.component';
import { TaxListComponent } from './masters/componets/tax-list/tax-list.component';
import { AddProductComponent } from './masters/componets/add-product/add-product.component';
import { ProductListComponent } from './masters/componets/product-list/product-list.component';
import { AddInvoiceComponent } from './invoice/components/add-invoice/add-invoice.component';
import { InvoiceListComponent } from './invoice/components/invoice-list/invoice-list.component';
import { AddCustomerComponent } from './masters/componets/add-customer/add-customer.component';
import { CustomerListComponent } from './masters/componets/customer-list/customer-list.component';
import { ProductAmcComponent } from './product-amc/components/product-amc/product-amc.component';
import { AmcListComponent } from './product-amc/components/amc-list/amc-list.component';
import { LoginPageComponent } from './login/components/login-page/login-page.component';


const routes: Routes = [
  {
    path: 'products',
    component: ProductListComponent
  },
  {
    path: 'products/add',
    component: AddProductComponent
  },
  {
    path: 'products/edit/:id',
    component: AddProductComponent
  },
  {
    path: 'customers',
    component: CustomerListComponent
  },
  {
    path: 'customers/add',
    component: AddCustomerComponent
  },
  // {
  //     path: 'customers/edit/:id',
  //     component: AddCustomerComponent
  // },
  {
    path: 'tax',
    component: TaxListComponent
  },
  {
    path: 'invoice/add',
    component: AddInvoiceComponent
  },
  {
    path: 'invoice/edit/:id',
    component: AddInvoiceComponent
  },
  {
    path: 'invoicelist',
    component: InvoiceListComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'amc',
    component: AmcListComponent
  },
  {
    path: 'addamc',
    component: ProductAmcComponent
  },
  {
    path: 'amc/edit/:id',
    component: ProductAmcComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
