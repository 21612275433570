import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";

import { Product } from "./../models/product";
import { AppCommonService } from 'src/app/app.common.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProductService {

  constructor(private httpClient: HttpClient,
    private appCommonService: AppCommonService) { }

  ServiceUrl: string = this.appCommonService.BaseServiceUrl + "ProductMaster";


  public get(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.ServiceUrl);
  }

  public getById(id: number): Observable<Product> {
    let getByIdUrl: string = this.ServiceUrl + "/" + id;
    return this.httpClient.get<Product>(getByIdUrl);
  }

  public save(product: Product): Observable<string> {
    console.log(this.ServiceUrl + "/" + product.Id);
    if (product.Id > 0)
      return this.httpClient.put<string>(this.ServiceUrl + "/" + product.Id, product);

    return this.httpClient.post<string>(this.ServiceUrl, product);
  }

}
