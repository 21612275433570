import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AppCommonService } from 'src/app/app.common.service';
import { TaxService } from '../../services/tax.service';
import { Tax } from '../../models/tax';

@Component({
  selector: 'app-tax-list',
  templateUrl: './tax-list.component.html',
  styleUrls: ['./tax-list.component.css'],
})
export class TaxListComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private appCommonService: AppCommonService,
    private taxService: TaxService) { }

  taxList: Tax[] = [];
  taxAddForm: FormGroup;
  successMsg: string = "";
  errorMsg: string = "";

  ngOnInit() {
    this.createTaxForm();
    this.loadTaxList();
  }

  loadTaxList() {
    this.appCommonService.showSpinner();
    this.taxService.get().subscribe(
      data => {
        this.taxList = data;
        console.log(data);
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }

  resetForm(): void {
    this.taxAddForm.reset();
    this.taxAddForm.controls.Id.setValue(0);
    this.taxAddForm.controls.IsDefault.setValue(false);
    this.successMsg = "";
    this.errorMsg = "";
  }

  saveTax() {
    let tax: Tax = this.taxAddForm.value;

    if (this.taxAddForm.invalid) {
      this.appCommonService.validateAllFormFields(this.taxAddForm);
      console.log(this.taxAddForm.errors);
      return;
    }

    this.appCommonService.showSpinner();
    this.taxService.save(tax).subscribe(
      data => {
        if (data == "Data Saved") {
          this.resetForm();
          this.loadTaxList();
        }
        else {
          this.errorMsg = data;
        }
      },
      err => {
        this.errorMsg = err;
        this.appCommonService.hideSpinner();
      },
      () => this.appCommonService.hideSpinner()
    )
  }

  editTax(id: number): void {
    let tax = this.taxList.find(x => x.Id == id);
    this.createEditForm(tax);
  }


  createTaxForm(): void {
    this.taxAddForm = this.formBuilder.group({
      Id: [0],
      TaxPercent: [, Validators.required],
      IsDefault: [false]
    })
  }

  createEditForm(tax:Tax):void{
    this.taxAddForm = this.formBuilder.group({
      Id: [tax.Id],
      TaxPercent: [tax.TaxPercent, Validators.required],
      IsDefault: [tax.IsDefault]
    });
  }

}
